import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

const AboutPage = () => {
    const { allStrapiArtist } = useStaticQuery(graphql`
    query {
        allStrapiArtist {
        group(field: season___year) {
          edges {
            node {
              name
              slug
              season {
                year
              }
            }
          }
        }
      }
      }
    `)

    function printArtist(queryResponse) {
        let listArray = []
        queryResponse.group.slice().reverse().forEach((grouppedSeason, index) => {
            listArray = [...listArray, <li class="list-none text-8xl text-tpBlue font-extrabold mt-10 mb-10" id={grouppedSeason.edges[0].node.season.year}>{grouppedSeason.edges[0].node.season.year}</li>]
            grouppedSeason.edges.forEach((number) => {
                listArray = [...listArray,
                <li class="max-sm:mt-12 lg:mt-8 xl:mt-8 list-none text-6xl text-tpBlue font-thin hover:text-yellow-500 hover:font-extralight cursor-pointer leading-tight">
                    <a href={`/artist/${number.node.slug}`}>{number.node.name}</a>
                </li>]
            })
        })
        return listArray
    }

    return (
        <Layout>
            <div className="container">
                <div class="font-light text-tpBlue border-dotted text-4xl border-b-2 border-t-2 border-tpBlue p-2 text-center mt-12">
                    Artists
                </div>
                <div class="flex justify-center">
                    <ul class="text-center">
                        {printArtist(allStrapiArtist)}
                    </ul>
                </div>
            </div>
        </Layout>
    )
}

export default AboutPage
